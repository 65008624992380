var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "item": _vm.item
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Slug ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.slug) + " ")])], 1)], 1)], 1), _vm._l(_vm.item.components, function (component, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-card-list', {
      attrs: {
        "title": component.name || component.schemaId.name,
        "item": _vm.item
      }
    }, [_c('vx-img', {
      attrs: {
        "rectangle": "",
        "src": component.schemaId.image
      }
    })], 1)], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }